<template>
  <layout>
    <template v-slot:title> Salgs overblik </template>
    <template v-slot:content>
      <stats-card :title="statsCards.title"></stats-card>
    </template>
  </layout>
</template>

<script>
import layout from "@/layouts/Default.vue";
import statsCard from "@/components/stats/statsCard.vue";
export default {
  components: {
    layout,
    statsCard,
  },
  data() {
    return {
      statsCards: {
        title: "sales.dashboard.cards.title",
        data: [{}],
      },
    };
  },
};
</script>

<style></style>
